import React from "react";

const Signup = () => {
  return (
    <div className="signup">
      <h1 className="space">Sign Up for more Information</h1>
      <h4>
        Enter your information if you would like to learn more about Narkman
        Academy
      </h4>

      {/* <!-- box to fill out your info -->
  {{#if message}}
  <div class="space2">
    <div class="bg-warning cent">
      <br>
        {{!-- <button type="button" class="close">&times;</button> --}}
        <div style="font-size: x-large;" class="space-bottom">
         {{message.message}}
        </div>
    </div>
  </div>
  {{/if}} */}
      <div className="signupCard">
        <form>
          <div className="form-group space">
            <label htmlFor="fullName">Full Name</label>
            <input
              type="name"
              className="form-control"
              id="fullName"
              placeholder="Please enter your full name."
            />
          </div>
          <div className="form-group space">
            <label htmlFor="exampleInputEmail1">Email address</label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Please enter your email address."
            />
          </div>
          <div className="form-group space">
            <label htmlFor="phoneNum">Phone Number</label>
            <input
              type="tel"
              className="form-control"
              id="phoneNum"
              placeholder="(###) ###-####"
            />
          </div>
          <div className="form-check space">
            <input
              type="checkbox"
              className="form-check-input"
              id="newsletter"
            />
            <label className="form-check-label" htmlFor="newsletter">
              Sign up for our newsletter.
            </label>
          </div>
          <button type="submit" className="btn btn-dark">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
