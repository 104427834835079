import React from "react";

const GoogleMap = () => {
  return (
    <div>
      {" "}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.747485240925!2d-118.07861404831621!3d34.05034798051124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2d0662b3b114d%3A0xf20e5337a631411f!2sWhittier%20Narrows%20Golf%20Course!5e0!3m2!1sen!2sus!4v1596168458657!5m2!1sen!2sus"
        width="100%"
        height="200"
        frameBorder="0"
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </div>
  );
};

export default GoogleMap;
