import React from "react";
import { useNavigate } from "react-router-dom";

const Lessons = () => {
  let navigate = useNavigate();
  const goToSignup = () => {
    console.log("hello");
    const path = "/Signup";
    navigate(path);
  };

  return (
    /* eslint-disable jsx-a11y/anchor-is-valid */
    <div className="lessons">
      <h1>Our Instructors</h1>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="picturePadding">
              <img src="pics/Jesse.jpeg" />
            </div>
            <p>
              Founder of Narkman Golf. He has been playing golf since he was 4
              years old. Can speak English, Mandarin, Thai
            </p>
          </div>
          <div className="col">
            <div className="picturePadding">
              <img src="pics/andrew.jpg" />
            </div>
            <p>
              Andrew plays collegiate golf at Saint Johns University. Has been
              playing competative golf as a junior and as a college athelete.
              Can speak English and Korean.{" "}
            </p>
          </div>
        </div>

        <h1>Lesson Packages</h1>

        <div className="row">
          <div className="col">
            <div className="lessonCard">
              <h4>Private Lessons with Jesse</h4>
              <p>1 hr</p>
              <p>$75</p>
              <button
                type="button"
                className="btn btn-light"
                onClick={goToSignup}
              >
                Book Now
              </button>
            </div>
          </div>

          <div className="col">
            <div className="lessonCard">
              <h4>Private Lessons with Andrew</h4>
              <p>1 hr</p>
              <p>$65</p>
              <button
                type="button"
                className="btn btn-light"
                onClick={goToSignup}
              >
                Book Now
              </button>
            </div>
          </div>

          <div className="col">
            <div className="lessonCard">
              <h4>Group Lessons (3 - 6 people)</h4>
              <p>1 hr</p>
              <p>$30</p>
              <button
                type="button"
                className="btn btn-light"
                onClick={goToSignup}
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lessons;
