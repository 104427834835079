import React, { Component } from "react";
import MyCarousel from "../components/MyCarousel";
import HomeTable from "../components/HomeTable";
import GoogleMap from "../components/GoogleMap";
import IconGrid from "../components/IconGrid";

export class Home extends Component {
  render() {
    return (
      <div>
        <MyCarousel></MyCarousel>
        <div className="grayGold title">
          <h1> NARKMAN GOLF</h1>
          <h2> Perfect Your Game</h2>
        </div>
        <IconGrid></IconGrid>

        <div className="grayWhite title">
          <h1>Our Story</h1>
          <h4>Making Golf Fun</h4>
          <p>
            Our goal is to make golf fun by empowering the golfer. We give you
            the tools to improve your game in as little as one lesson. Our
            instructors have been in the golf industry for more than 10 years.
            By improving each part of your golf game, you will have more fun and
            look forward to going to the course. Come join us at Whittier
            Narrows and then join the community that is Narkman Golf.
          </p>
        </div>

        <tr>
          <td>
            <img src="pics/DSC_8871.jpg" />
          </td>
        </tr>

        <HomeTable></HomeTable>
        <GoogleMap></GoogleMap>
      </div>
    );
  }
}

export default Home;
