import React from "react";

const IconGrid = () => {
  return (
    <div className="grid">
      <table>
        <tr>
          <td>
            <img src="pics/golf2.jpg" />
          </td>
          <td>
            <img src="pics/golf3.jpg" />
          </td>
          <td>
            <img src="pics/golf4.jpg" />
          </td>
        </tr>

        <tr className="shad">
          <td>
            <h3>One-on-one</h3>
            <p>
              Custom taylor your lesson to what you want improved most in your
              game. Or to what your instructor thinks is the best. All up to
              you!
            </p>
          </td>
          <td>
            <h3>Group Lessons</h3>
            <p>
              Growing Together. Perfect for the beginner on a budget or the the
              more advanced player who just wants to brush up on some
              fundamentals.
            </p>
          </td>
          <td>
            <h3>Multi-Languages</h3>
            <p>Ask about what other languages our instructors speak</p>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default IconGrid;
