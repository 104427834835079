import React from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";

const HomeTable = () => {
  return (
    <div className="homeTable">
      <h2>What We Do</h2>

      <div>
        <h5>Golf Swing Analysis</h5>
        <ul>
          <li>We use technology to give more the golfer more feedback</li>
          <li>An ipad is present every lesson</li>
          <li>
            Shot tracker gives all the data including distance, ball speed
          </li>
        </ul>
        <h5>Teach Course Management</h5>
        <ul>
          <li>
            On course lessons teach managing your game to shoot better scores
          </li>
          <li>
            We teach when to hit different shot types such as high, low, fade,
            draw.
          </li>
          <li>
            How to hit out of different lies such as the rough, hard pan, ball
            below or above feet.
          </li>
        </ul>
        <h5>Develop interest for the game in youths</h5>
        <ul>
          <li>We have summer camps for youths during summer break. </li>
          <li>Fun group outings take the seriousness out of the game</li>
          <li>
            Recommendations for jr tournaments to play if the youth is getting
            more serious.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HomeTable;
