import "./App.css";
import { BrowserRouter, Link, NavLink, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Lessons from "./pages/Lessons";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <header>
          <nav className="grayGold">
            <h1>Narkman Academy</h1>
            <NavLink to="/">Home</NavLink>
            <NavLink to="Lessons">Lessons</NavLink>
            <NavLink to="Signup">Signup</NavLink>
            <NavLink to="Contact">Contact</NavLink>
          </nav>
        </header>
        <main>
          <Routes>
            <Route index element={<Home />} />
            <Route path="Lessons" element={<Lessons />} />
            <Route path="Signup" element={<Signup />} />
            <Route path="Contact" element={<Contact />} />
          </Routes>
        </main>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}

export default App;
