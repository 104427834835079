import React from "react";
import GoogleMap from "../components/GoogleMap";
import { MDBTable, MDBTableBody } from "mdb-react-ui-kit";

const Contact = () => {
  return (
    <div>
      <div className="contact">
        <h1>Where We Are</h1>
        <div>
          <h4>Location & Contact Details</h4>
          <div className="shadow">
            <MDBTable>
              <MDBTableBody>
                <tr className="darkRow">
                  <th scope="Tel:">Tel:</th>
                  <td>(626) 288-1044</td>
                </tr>
                <tr>
                  <th scope="Email">Email:</th>
                  <td>narkmangolf@gmail.com</td>
                </tr>
                <tr className="darkRow">
                  <th scope="Address">Address:</th>
                  <td>8640 Rush St, Rosemead, CA 91770, USA</td>
                </tr>
                <tr>
                  <th scope="Website">Website:</th>
                  <td>
                    <a href="https://www.whittiernarrowsgc.com/">
                      Whittier Narrows Golf Course Website{" "}
                    </a>
                  </td>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </div>
        </div>
      </div>
      <GoogleMap></GoogleMap>
    </div>
  );
};

export default Contact;
